import React, { useEffect, useRef } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ChildPages from "../components/ChildPages";
import BackgroundImage from "gatsby-background-image";
// import { Parallax, Background } from "react-parallax";
import Rellax from "rellax";

/* can't get variable with static query, so using dynamic and exporting it */

export const query = graphql`
  query($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      title
      content
      wordpress_id
      date(formatString: "MMMM DD, YYYY")
    }
  }
`;

const Page = (props) => {
  return (
    <Layout>
      <div className="container container--page container--post">
        <h1 className="post--title">{props.data.wordpressPost.title}</h1>
        <div className="post--date">{props.data.wordpressPost.date}</div>
        <div dangerouslySetInnerHTML={{ __html: props.data.wordpressPost.content }}></div>
      </div>
    </Layout>
  );
};

export default Page;
